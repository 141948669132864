import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import t from "../../../lib/translate";
import Headline from "../../headline";
import FormField from "../form-components/form-field";
import FormGroup from "../form-components/form-group";
import FormContext from "../../../layouts/form-layout/FormContext";
import FormService from "../../../lib/services/form";
import SuccessPage from "../SuccessPage";

export default function PlanRequestForm({ formId }) {
	const fromService = new FormService(process.env.GATSBY_API_FORM_URL);

	const { formSubmitted, setFormSubmitted } = useContext(FormContext);

	const [errorMessage, setErrorMessage] = useState("");

	async function submitForm(values) {
		const formData = JSON.parse(JSON.stringify(values));
		formData.callback = formData.callback === true ? 1 : 0;
		formData.gdpr = formData.gdpr === true ? 1 : 0;

		try {
			const result = await fromService.submit("tarrif", formData);

			if (result) {
				setErrorMessage(t("page.form.general.error"));
			} else {
				setFormSubmitted(true);
			}
		} catch (error) {
			console.error(error);
			setErrorMessage(t("page.form.general.error"));
		}

		window[`scrollTo`]({ top: 0, behavior: `smooth` });
	}

	if (formSubmitted) return <SuccessPage />;

	return (
		<>
			<Formik
				initialValues={{
					plan: "",
					salutation: "",
					firstName: "",
					lastName: "",
					email: "",
					phone: "",
					street: "",
					number: "",
					zip: "",
					city: "",
					message: "",
					callback: "",
					dataProcess: "",
					gdpr: "",
				}}
				validationSchema={Yup.object({
					plan: Yup.string()
						.required(t`page.plan-request.error-message`)
						.oneOf(
							t("page.plan-request.label.plan.values").split(","),
							t("page.plan-request.plan.error-message")
						),
					salutation: Yup.string()
						.required()
						.oneOf(
							t("page.plan-request.label.salutation.values").split(","),
							t`page.plan-request.error-message`
						),
					firstName: Yup.string(),
					lastName: Yup.string().required(t`page.plan-request.error-message`),
					email: Yup.string()
						.email(t`page.plan-request.invalid.email`)
						.required(t`page.plan-request.error-message`),
					phone: Yup.string(),
					street: Yup.string().required(t`page.plan-request.error-message`),
					number: Yup.string().required(t`page.plan-request.error-message`),
					zip: Yup.string().required(t`page.plan-request.error-message`),
					city: Yup.string().required(t`page.plan-request.error-message`),
					message: Yup.string(),
					callback: Yup.boolean(),
					dataProcess: Yup.string().required(
						t`page.plan-request.error-message`
					),
					gdpr: Yup.bool().required(t`page.plan-request.error-message`),
					// invalidCheck: Yup.boolean().oneOf([true], t`validation_err_required`).required(t`validation_err_required`),
				})}
				onSubmit={submitForm}
			>
				<Form id={formId}>
					<legend>
						<Headline semanticOfHeadline="H1">
							{t("page.plan-request.header")}
						</Headline>
						{t`page.plan-request.subheader`}
					</legend>

					<span className="errorMsg">{errorMessage}</span>

					<FormField
						type="select"
						name="plan"
						label={t("page.plan-request.label.plan")}
						placeholder={t("page.plan-request.placeholder.plan")}
						options={[
							{ name: t("page.plan-request.placeholder.plan") },
							...t("page.plan-request.label.plan.values")
								.split(",")
								.map((option) => ({ name: option, value: option })),
						]}
						required
					/>

					<FormField
						type="select"
						name="salutation"
						label={t("page.plan-request.label.salutation")}
						placeholder={t("page.plan-request.placeholder.salutation")}
						options={[
							{ name: t("page.plan-request.placeholder.salutation") },
							...t("page.plan-request.label.salutation.values")
								.split(",")
								.map((option) => ({ name: option, value: option })),
							,
						]}
						required
					/>

					<FormField
						type="text"
						name="firstName"
						label={t`page.plan-request.label.firstname`}
						placeholder={""}
					/>

					<FormField
						type="text"
						name="lastName"
						label={t`page.plan-request.label.lastname`}
						placeholder={""}
						required
					/>

					<FormField
						type="text"
						name="email"
						label={t`page.plan-request.label.email`}
						placeholder={""}
						required
					/>

					<FormField
						type="text"
						name="phone"
						label={t`page.plan-request.label.phone`}
						placeholder={""}
					/>

					<FormField
						type="text"
						name="street"
						label={t`page.plan-request.label.street`}
						placeholder={""}
						required
					/>

					<FormField
						type="text"
						name="number"
						label={t`page.plan-request.label.number`}
						placeholder={""}
						required
					/>

					<FormField
						type="text"
						name="zip"
						label={t`page.plan-request.label.zip`}
						placeholder={""}
						required
					/>

					<FormField
						type="text"
						name="city"
						label={t`page.plan-request.label.city`}
						placeholder={""}
						required
					/>

					<FormField
						type="textarea"
						name="message"
						label={t`page.plan-request.label.message`}
						rows="10"
						placeholder={""}
					/>

					<FormGroup label={t`page.plan-request.label.callback`}>
						<FormField
							type="checkbox"
							name="callback"
							label={t`page.plan-request.callback.value1`}
							inputFirst
						/>
					</FormGroup>

					<FormField
						type="radiogroup"
						name="dataProcess"
						label={t`page.plan-request.label.data-process`}
						options={[
							{
								label: t`page.plan-request.data-process.value1`,
								value: t`page.plan-request.data-process.value1`,
							},
							{
								label: t`page.plan-request.data-process.value2`,
								value: t`page.plan-request.data-process.value2`,
							},
						]}
						required
					/>

					<FormField
						type="checkbox"
						name="gdpr"
						label={t`page.plan-request.label.gdpr`}
						inputFirst
						required
					/>
				</Form>
			</Formik>
		</>
	);
}
