import React from 'react'
import * as styles from './component.module.less'

export default function FormGroup(props) {
    const {
        children,
        label,
        required 
    } = props

    return (
        <div className={styles.formGroup}>
            <span>{label}</span> {required && <span className={styles.asterisc}>*</span>}
            {children}
        </div>
    )
}
