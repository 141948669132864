import React, { Fragment } from 'react'
import * as styles from './component.module.less'
import SectionContainer from '../../components/section-container'
import Headline from '../../components/headline'
import StructuredTextRenderer from '../../components/structured-text-renderer'
import createHtmlClass from '../../lib/helper/createHtmlClass'


export default function Download(props) {
    const {
        headline,
        semanticOfHeadline,
        downloadText,
        downloadGroup,
        background
    } = props.data
    return (
        <SectionContainer 
            sectionClass={styles.downloadSection}
            background={background}
        >
            <Headline
                className={styles.downloadSectionHeadline}
                semanticOfHeadline={semanticOfHeadline}
            >
                {headline}
            </Headline>

            <StructuredTextRenderer 
                className={styles.downloadText}
                data={downloadText}
            />

            {downloadGroup.map(groupElement => (
                <div key={groupElement.id} className={styles.downloadGroup}>
                    <Headline
                        className={styles.downloadGroupHeadline}
                    >
                        {groupElement.downloadHeadline}
                    </Headline>

                    <div className={styles.downloadLinkList}>
                        {groupElement.download.map(download => (
                            <Fragment key={download.id}>
                                {download?.file?.url &&
                                    <a 
                                        className={styles.downloadLink}
                                        href={download.file.url}
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        <div
                                            className={createHtmlClass(['icon-btn','btn-file-orange', styles.downloadLinkIcon, styles.file])}
                                        />
                                        {download.name}
                                    </a>
                                }
                                {download.externalUrl &&
                                    <a 
                                        className={styles.downloadLink}
                                        href={download.externalUrl}
                                        target='_blank'
                                        rel="noreferrer"
                                    >
                                        {download.name}
                                        <div className={createHtmlClass(['icon-btn','btn-arrow-right', styles.downloadLinkIcon, styles.external])}/>
                                    </a>
                                }
                            </Fragment>
                        ))}
                    </div>
                </div>
            ))}
        </SectionContainer>
    )
}
