import React from 'react'
import { Field } from 'formik'

export default function TextAreaInput(props) {
    const {
        className,
        name,
        rows = 10,
        placeholder,
    } = props

    return (
        <Field 
            className={className}
            name={name}
            as='textarea'
            rows={rows}
            type='text'
            placeholder={placeholder}
        />
    )
}
