// extracted by mini-css-extract-plugin
export var mediaTextSliderContainer = "component-module--mediaTextSliderContainer--w0aA5";
export var mediaTextSlider = "component-module--mediaTextSlider--vqvLT";
export var mediaTextSliderItem = "component-module--mediaTextSliderItem--h-vYv";
export var mediaTextSliderItemImage = "component-module--mediaTextSliderItemImage--+4SdE";
export var mediaTextSliderTextBox = "component-module--mediaTextSliderTextBox--2L7Wy";
export var mediaTextSliderHeadline = "component-module--mediaTextSliderHeadline--2RJYh";
export var mediaTextSliderContent = "component-module--mediaTextSliderContent--gD0tj";
export var mediaTextSliderLink = "component-module--mediaTextSliderLink--T-3nl";
export var mediaTextSliderControls = "component-module--mediaTextSliderControls--U5gJL";
export var mediaTextSliderNavPrev = "component-module--mediaTextSliderNavPrev--+Toa3";
export var mediaTextSliderPagination = "component-module--mediaTextSliderPagination--yF3oK";
export var mediaTextSliderBullets = "component-module--mediaTextSliderBullets--pKWo-";
export var mediaTextSliderBulletActive = "component-module--mediaTextSliderBulletActive--0VrFa";
export var mediaTextSliderNavNext = "component-module--mediaTextSliderNavNext--0vG4a";