import React from "react";
import * as styles from "./component.module.less";
import SectionContainer from "../../components/section-container";
import Headline from "../../components/headline";
import PlanProduct from "./plan-product";

export default function PlanOverview(props) {
	const { headline, semanticOfHeadline, plan } = props.data;

    debugger

	return (
		<SectionContainer className={styles.broadbandPlanContainer}>
			<Headline semanticOfHeadline={semanticOfHeadline}>{headline}</Headline>

			<div className={styles.broadbandPlanProductContainer}>
				{plan.map((product) => (
					<>
						<PlanProduct
							key={product.id}
							data={product}
							linkPlanOverview={props.linkPlanOverview}
						/>
					</>
				))}
			</div>
		</SectionContainer>
	);
}
