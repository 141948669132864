import React from 'react'
import * as styles from './component.module.less'
import SectionContainer from '../../components/section-container'
import Headline from '../../components/headline'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import createHtmlClass from '../../lib/helper/createHtmlClass'
import StructuredTextRenderer from '../../components/structured-text-renderer'

export default function MediaTextSlider(props) {
    const {
        slider,
        background
    } = props.data;

    const idButtonPrev = 'button-prev';
    const idButtonNext = 'button-next';

    return (
        <SectionContainer className={styles.mediaTextSliderContainer} background={background}>
            <Swiper
                modules={[Navigation, Pagination, A11y, Autoplay ]}
                className={styles.mediaTextSlider}
                spaceBetween={50}
                slidesPerView={1}
                centeredSlides={true}
                loop={true}
                speed={1000}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false
                }}
                navigation={{
                    prevEl: `#${idButtonPrev}`,
                    nextEl: `#${idButtonNext}`,    
                }}
                pagination={{
                    "clickable": true,
                    type: 'bullets',
                    el: `.${styles.mediaTextSliderPagination}`,
                    bulletElement: 'div',
                    bulletClass: styles.mediaTextSliderBullets,
                    bulletActiveClass: styles.mediaTextSliderBulletActive
                }}
            >
                {slider.map((sliderElement, index) => {
                    const {
                        id,
                        headline,
                        semanticOfHeadline,
                        media,
                        text,
                        link
                    } = sliderElement

                    return (
                        <SwiperSlide className={styles.mediaTextSliderItem} key={id} virtualIndex={index}>
                            <GatsbyImage
                                className={styles.mediaTextSliderItemImage}
                                image={getImage(media.gatsbyImageData)}
                                alt={media.alt ?? ''} />
                            <div className={styles.mediaTextSliderTextBox}>
                                <Headline className={styles.mediaTextSliderHeadline} semanticOfHeadline={semanticOfHeadline}>{headline}</Headline>

                                <StructuredTextRenderer className={styles.mediaTextSliderContent} data={text}/>
                                <StructuredTextRenderer className={styles.mediaTextSliderLink} data={link}/>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>

            <div className={styles.mediaTextSliderControls}>
                <div className={styles.mediaTextSliderPagination} />
                <button id={idButtonNext} className={createHtmlClass([styles.mediaTextSliderNavNext, 'icon-btn', 'btn-arrow-right'])}>
                    Next
                </button>
            </div>
        </SectionContainer>
    )
}