import React, { createRef, useState } from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";

import DefaultLayout from "./layouts/default";
import FormLayout from "./layouts/form-layout";

import ContactForm from "./components/forms/contact";
import PlanRequestForm from "./components/forms/plan-request";
import NoAddressForm from "./components/forms/no-address";
import CancellationForm from "./components/forms/cancellation";

import HeaderRenderer from "./components/header-renderer";
import ContentElementRenderer from "./components/content-element-renderer";
import CookieConsent from "./components/cookie-consent";

const layouts = {
	default: DefaultLayout,
	form: FormLayout,
};

const forms = {
	form_contact: ContactForm,
	"form_plan-request": PlanRequestForm,
	"form_no-address": NoAddressForm,
	form_cancellation: CancellationForm,
};

export default function PageTemplate({ data, pageContext }) {
	const currentLocale = pageContext.locale ?? "de";

	const config = data.allDatoCmsConfig.nodes.filter(
		(node) => node.locale === currentLocale
	)[0];

	const Form = forms[pageContext.layout.layoutId] || null;

	const layoutIsForm = !!Form;

	const Layout = layoutIsForm ? layouts.form : layouts.default;

	const cookieConsentRef = createRef();

    // SEO
    const { slug, lang, metaRobots, metaCanonical, seoMetaTags } = data.seo;
	const metaIndexing =
		!metaRobots || /^index/.test(metaRobots || "") || metaRobots === "all";

	const seoData = seoMetaTags || { tags: [] };

	seoData.tags = seoData.tags.filter((s) => {
		if (
			!s.attributes ||
			(!s.attributes.property && s.attributes.name !== "robots")
		) {
			return true;
		}

		if (s.attributes && s.attributes.name === "robots") {
			return false;
		}

		return (
			s.attributes.property !== "article:modified_time" &&
			s.attributes.property !== "article:published_time"
		);
	});

	return (
		<>
			<HelmetDatoCms seo={seoData}>
				<html lang={lang} />

				{metaRobots && (
					<meta name="robots" content={metaRobots.replace(/ /, ",")} />
				)}

				{metaIndexing && (
					<link
						rel="canonical"
						href={`${process.env.GATSBY_BASE_URL}/${
							(metaCanonical?.slug === "index" ? "" : metaCanonical?.slug) ||
							(slug === "index" ? "" : slug)
						}`}
					/>
				)}
			</HelmetDatoCms>

			<CookieConsent locale={currentLocale} visibilityRef={cookieConsentRef} />

			<Layout
				locale={pageContext.locale}
				config={config}
				cookieConsentRef={cookieConsentRef}
				formId={pageContext.layout.layoutId}
			>
				{layoutIsForm ? (
					<Form formId={pageContext.layout.layoutId} />
				) : (
					<>
						{(data.datoCmsPage.header || []).length > 0 && (
							<HeaderRenderer
								items={data.datoCmsPage.header}
								locale={pageContext.locale}
							/>
						)}
						{(data.datoCmsPage.content || []).length > 0 && (
							<ContentElementRenderer
								items={data.datoCmsPage.content}
								locale={pageContext.locale}
								config={config}
							/>
						)}
					</>
				)}
			</Layout>
		</>
	);
}

// $locale: String!,
export const query = graphql`
	query ($id: String!) {
		datoCmsPage(id: { eq: $id }) {
			id
			...cms_header
			...cms_content_page
		}
		seo: datoCmsPage(id: { eq: $id }) {
			slug
			lang: locale
			metaRobots
			metaCanonical {
				... on DatoCmsPage {
					slug
				}
				... on DatoCmsNews {
					slug
				}
			}
			seoMetaTags {
				tags
			}
		}
		allDatoCmsConfig {
			nodes {
				id
				locale
				pageIndex {
					id
					name
					slug
				}
				pageContact {
					id
					name
					slug
				}
				pageDataProtection {
					id
					name
					slug
				}
				pageImprint {
					id
					name
					slug
				}
				pageDisruption {
					id
					name
					slug
				}
				pageCancellation {
					id
					name
					slug
				}
				department {
					name
					street
					number
					zip
					place
					phone
					fax
					email
					openingHours {
						blocks
						links
						value
					}
				}
				serviceHotline {
					id
					name
					phone
					eMail
				}
				socialMediaChannels {
					id
					name
					url
				}
				cookieConsentHeadline
				cookieConsentIntro
				cookieConsentSaveselBtnLabel
				cookieConsentSettingsBtnLabel
				cookieConsentPosallBtnLabel
				cookieConsentGroups {
					name
					description
					id
					uid
				}
				linkPlanOverview: buttonLinkPlanOverview
                linkToLogin
			}
		}
	}
`;
