import React from "react";
import * as styles from "./component.module.less";
import Headline from "../../components/headline";
import StructuredTextRenderer from "../../components/structured-text-renderer";

const HeaderMediaText = (props) => {
  const { 
    ctaButton,
    secundarButton,
    mediaAlignLeft,
    tagline,
    semanticOfHeadline,
    headline,
    subheadline,
    media
  } = props.data

  let containerClasses = [styles.container];

  if (mediaAlignLeft === true) {
    containerClasses.push(styles.alignLeft);
  }

  return (
    <section className={`${containerClasses.join(" ")} `}>
      <div className={styles.heading}>
        <header>
          <span>{tagline}</span>
          <Headline semanticOfHeadline={semanticOfHeadline}>{headline}</Headline>
          <StructuredTextRenderer data={subheadline} />
        </header>
        <div className={styles.buttonContainer}>
          <StructuredTextRenderer
            data={ctaButton}
            itemLinkClass={styles.ctaButton}
          />
          <StructuredTextRenderer
            data={secundarButton}
          />
        </div>
      </div>
      <figure className={styles.media}>
        {media.fluid && (
          <img src={media.fluid.src} alt={media.alt} />
        )}
        {media.video && (
          <video
            src={media.video.mp4Url}
            controls={true}
            autoPlay={true}
          >
            <track kind="captions"/>
          </video>
        )}
      </figure>
    </section>
  );
};

export default HeaderMediaText;
