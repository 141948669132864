import React from "react";
import * as styles from "./component.module.less";
import Headline from "../../components/headline";
import { renderRule } from 'react-datocms';
import SectionContainer from "../../components/section-container";
import StructuredTextRenderer from "../../components/structured-text-renderer";
import { isParagraph } from "datocms-structured-text-utils";
import createHtmlClass from "../../lib/helper/createHtmlClass";

const departmentVarPrefix = process.env.GATSBY_DEPARTMENT_PREFIX ?? 'main.department.'

const Text = (props) => {
  const {
    headline,
    headlinePosition,
    semanticOfHeadline, 
    subheadline,
    ctaButton, 
    secundarButton,
    background,
  } = props.data

  const replaceDepartmentVars = renderRule(isParagraph, ({ children, key }) => {
    const department = props.config.department;

    const replacedChilds = children.map(child => {
      if (typeof child !== 'string') { return child }
      
      return child.replaceAll(/\(\(([a-z1-9.-]+)\)\)/g, (fullMatch, group) => {
        if (!group.startsWith(departmentVarPrefix)) { return fullMatch }

        const replacementVar = group.split(departmentVarPrefix)[1]
        return department[replacementVar] ?? fullMatch
      });
    })
    
    return (
      <p key={key}>{replacedChilds}</p>
    );
  })

  return (
    <SectionContainer 
        className={styles.textContainer}
        sectionClass={createHtmlClass([
            styles.textContentSection,
            headlinePosition ? styles.stacked : ''
        ])}
        background={background}
    >
      <div className={styles.textBox}>
        <Headline semanticOfHeadline={semanticOfHeadline}>{headline}</Headline>
      </div>

      <div className={styles.subHeadContainer}>
        <StructuredTextRenderer
            data={subheadline}
            className={styles.textSubHeadline}
            customRules={[replaceDepartmentVars, renderRule(isParagraph, (props) => {debugger})]}
        />
        <div className={styles.textButtonContainer}>
          <StructuredTextRenderer className={styles.button}
              data={ctaButton}
          />
          <StructuredTextRenderer className={styles.button}
              data={secundarButton}
          />
        </div>
      </div>
    </SectionContainer>
  );
};

export default Text;
