import React from 'react'
import * as styles from './component.module.less'
import Headline from '../../../components/headline'
import StructuredTextRenderer from '../../../components/structured-text-renderer'
import SectionContainer from '../../../components/section-container'

export default function TextAdvantage(props) {
    const {
        headline,
        semanticOfHeadline,
        text,
        advantage,
        background
    } = props.data

    return (
        <SectionContainer className={styles.textAdvantageContainer} sectionClass={styles.textAdvantageContentSection} background={background}>
            <div className={styles.textAdvantageTextContainer}>
                <Headline 
                    className={styles.textAdvantageHeadline}
                    semanticOfHeadline={semanticOfHeadline}
                >
                    {headline}
                </Headline>

                <StructuredTextRenderer className={styles.textAdvantageText} data={text}/>
            </div>

            <StructuredTextRenderer className={styles.textAdvantageAdvantages} data={advantage} />
        </SectionContainer>
    )
}
