import React from "react";
import { StaticQuery, graphql } from "gatsby";
import * as styles from "./component.module.less";
import NewsTeaserElement from "./news-teaser-element";
import Headline from "../../components/headline";
import SectionContainer from "../../components/section-container";

const NewsTeaser = ({background, semanticOfHeadline, headline, locale, }) => {

  return (
    <SectionContainer className={styles.container} background={background}>
      <header>
        <Headline semanticOfHeadline={semanticOfHeadline}>{headline}</Headline>
      </header>
      <StaticQuery
        query={graphql`
          query news($catId: String) {
            allDatoCmsNews(filter: {category: {elemMatch: {originalId: {eq: $catId}}}}) {
              nodes {
                title
                id
                mainImage {
                  alt
                  gatsbyImageData (
                    layout: FIXED
                    forceBlurhash: true
                    imgixParams: {w: "340", h: "200", auto: "compress", fit: "crop", crop: "faces", dpr: 1.5, q: 75}
                  )
                }
                shortDescription {
                  blocks
                  links
                  value
                }
                slug
              }
            } 
          }
        `}
        render={(data) => {
          return(
          <div className={styles.teaser}>
            {data.allDatoCmsNews.nodes.map((news, i) => {
              if (news.title) {
                return (
                  <NewsTeaserElement
                    key={i}
                    locale={locale}
                    header={news.title}
                    image={news.mainImage}
                    description={news.shortDescription}
                    slug={news.slug}
                  />
                )
              } else {
                return null;
              }
            })}
          </div>
        )}}
      />
    </SectionContainer>
  );
};

export default NewsTeaser;