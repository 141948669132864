import React from 'react';
import * as styles from './component.module.less'
import t from "../../lib/translate"

export function ContactInfo(props) {
    const {
        street,
        number,
        zip,
        place,
        email,
        phone    
    } = props.contactInfo

    return (
        <div className={styles.contactInfoBox}>
            <p>
                {t('page.serviceteaser.introduction')} <br/>
                {street} {number}, {zip} {place}
            </p>

            <p>
                {t('page.serviceteaser.phoneintro')} <br/>
                <span className={styles.contactInfoPhone}>{phone}</span>
            </p>
            
            <p>
                {t('page.serviceteaser.email')} <span className={styles.contactInfoMail}>{email}</span>
            </p>
        </div>
    )
}
