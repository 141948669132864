import React from "react";
import * as styles from "./component.module.less";
import SectionContainer from "../../components/section-container";
import { GatsbyImage } from "gatsby-plugin-image";

const Quote = ({ background, quote, image, name }) => {
  return (
    <SectionContainer className={styles.container} background={background}>
      <div className={styles.quote}>
        <span>{quote}</span>
        <div className={styles.author}>
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.alt}
            title={image.title}
            className={styles.image}
          />
          <span>{name}</span>
        </div>
      </div>
    </SectionContainer>
  );
};

export default Quote;
