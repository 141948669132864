import React from "react"
import HeaderHeadlineImage from "../../content-elements/1.01-header-headline-image"
import HeaderMediaText from "../../content-elements/header-media-text"
import HeaderText from "../../content-elements/1.02-header-text"
// import * as styles from "./component.module.less"

const HeaderRenderer = (props) => {

    const items = props.items || []

    return (
        <>
            <>{props.children}</>
            {items.map((headerElement, i) => {
                switch ((headerElement?.internal?.type) || null) {
                    case "DatoCmsHeaderMediaText":
                        return (
                            <HeaderMediaText 
                                key={headerElement.id}
                                locale={props.locale}
                                data={headerElement}
                            />
                        )
                    case "DatoCmsHeaderText":
                        return (
                            <HeaderText
                                key={headerElement.id}
                                locale={props.locale}
                                data={headerElement}
                            />
                        )
                    case "DatoCmsHeaderHeadlineImage":
                        return (
                            <HeaderHeadlineImage
                            key={headerElement.id}
                            locale={props.locale}
                            data={headerElement}
                            />
                        )
                    default:
                        return (
                            <div key={i}>
                              unknown content element:{" "}
                              {(headerElement?.internal?.type) || "unknown type"}
                            </div>
                        )
                }
            })}
        </>
    )

}

export default HeaderRenderer