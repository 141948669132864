import React, { useState } from "react";
import * as styles from "./component.module.less";
import Headline from "../../components/headline";
import SectionContainer from "../../components/section-container";
import { GatsbyImage } from "gatsby-plugin-image";

const MediaSlider = ({
  background,
  tagline,
  semanticOfHeadline,
  headline,
  images,
}) => {
  const [shown, setShown] = useState(0);

  let containerClasses = [styles.container];
  let [buttonClass, setButtonClass] = useState();

  return (
    <SectionContainer
      className={containerClasses}
      background={background}
      onButtonClass={setButtonClass}
    >
      <header>
        <span>{tagline}</span>
        <Headline semanticOfHeadline={semanticOfHeadline}>{headline}</Headline>
      </header>
      <div className={styles.slider}>
        <button
          onClick={() => {
            setShown(shown - 1);
          }}
          disabled={shown === 0}
          className={buttonClass}
        >
          {"<"}
        </button>
        <div className={styles.media}>
          {images.map((image, i) => {
              return (
                <figure key={i} className={styles.image} style={(i === shown) ? {} : {display: "none"}}>
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    alt={image.alt}
                  />
                  <span>{image.title}</span>
                </figure>
              );
            })}
        </div>
        <button
          onClick={() => {
            setShown(shown + 1);
          }}
          disabled={shown === images.length - 1}
          className={buttonClass}
        >
          {">"}
        </button>
      </div>
    </SectionContainer>
  );
};

export default MediaSlider;
