import React, {useState} from 'react'
import StructuredTextRenderer from '../../../components/structured-text-renderer';
import createHtmlClass from '../../../lib/helper/createHtmlClass';
import * as styles from "./component.module.less";

export default function Question(props) {
    const {question, answer} = props.data;
    const [active, setActive] = useState(false);

    return (
        <div className={createHtmlClass([styles.questionBox, active ? styles.active : ''])}>
            <button className={styles.question} onClick={() => setActive(!active)}>
                <p>{question}</p>
                <div className={`${styles.faqQuestionBtn} ${'icon-btn btn-close-orange'}`}/>
            </button>

            <div className={styles.answer}>
                <StructuredTextRenderer data={answer} noContainer={true}/>
            </div>
        </div>
    )
}
