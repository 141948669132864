import React from 'react'
import { Field } from 'formik'

export default function SelectInput(props) {
    const {
        className,
        name,
        placeholder,
        options
    } = props

    if (!options.length) {
        return (
            <div>THERE ARE NO OPTIONS!</div>
        )
    }

    return (
			<Field
				className={className}
				name={name}
				as="select"
				placeholder={placeholder}
			>
				{options.map(({ name: optionName, value: optionValue }, key) => (
                    <option key={`${name}-${key}`} value={optionValue}>{optionName}</option>
                ))}
			</Field>
		);
}
