import React from "react";
import * as styles from "./component.module.less";
import Headline from "../../../components/headline";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import StructuredTextRenderer from "../../../components/structured-text-renderer";
import createHtmlClass from "../../../lib/helper/createHtmlClass";

const TeaserElement = ({locale, data, semanticOfHeadline, showImage = true, showText = true, stretch = false}) => {

    const {
        headline,
        text,
        link,
        label,
        image
    } = data

    const teaserImage = getImage(image.gatsbyImageData)

    // class list for teaser element container
    const conteinerClasses = [styles.teaserElementContainer]

    if (showImage) {
        conteinerClasses.push(styles.teaserElementCard)
    }
    if (stretch) {
        conteinerClasses.push(styles.teaserElementStrech)
    }

    return (
        <div className={createHtmlClass(conteinerClasses)}
        >
            <div className={styles.teaserElementImageContainer}>
                {(label && showImage) && 
                    <span className={styles.teaserElementImageLabel}>{label}</span>
                }

                {showImage &&
                    <GatsbyImage
                        className={styles.teaserElementImage}
                        image={teaserImage}
                        alt={image.alt ?? ''}
                    />
                }
            </div>

            <div className={styles.teaserElementContentBlock}>
                <Headline
                    className={styles.teaserElementHeadText}
                    semanticOfHeadline={semanticOfHeadline}
                >
                    {headline}
                </Headline>

                {showText &&
                    <StructuredTextRenderer className={styles.teaserElementText} data={text}/>
                }

                <StructuredTextRenderer
                    className={styles.linkButton}
                    data={link}
                />
            </div>
        </div>
    );
};

export default TeaserElement;