import React  from "react";
import * as styles from "./component.module.less";
import parse from "html-react-parser"

export default function AxpIframeEmbed( props ) {
    const { axpEmbed } = props

    return (
        <div className={styles.iframeContainer}>
            {parse(axpEmbed.iframeSourcecode)}
        </div>
    )
}
