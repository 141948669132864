import React from 'react'
import { Field } from 'formik'

export default function CheckboxInput(props) {
    const {
        className,
        name,
    } = props

    return (
        <Field className={className} name={name} type='checkbox'/>
    )
}
