// extracted by mini-css-extract-plugin
export var product = "component-module--product--hioeI";
export var productHeaderContainer = "component-module--productHeaderContainer--SofCR";
export var productLabel = "component-module--productLabel--2JbaY";
export var productLabelPromotion = "component-module--productLabelPromotion--dCdDs";
export var productName = "component-module--productName--OkIHL";
export var productPlanName = "component-module--productPlanName--aWt4b";
export var productDetailsContainer = "component-module--productDetailsContainer--VAm3q";
export var detailsTop = "component-module--detailsTop--wA+pO";
export var productPrice = "component-module--productPrice--Md9F0";
export var productPriceDisabled = "component-module--productPriceDisabled--AuzN7";
export var productPromotionPrice = "component-module--productPromotionPrice--R6nH0";
export var productPromotionDuration = "component-module--productPromotionDuration--U0OxQ";
export var productTaxHint = "component-module--productTaxHint--R3hFn";
export var productFeatures = "component-module--productFeatures--nG0gC";
export var wlanPlanDetails = "component-module--wlanPlanDetails--zGsFn";
export var productSelectButtonContainer = "component-module--productSelectButtonContainer--otY2C";
export var wlanPlanButtonContainer = "component-module--wlanPlanButtonContainer--sHTm0";
export var productDownloadLink = "component-module--productDownloadLink--Ng3U+";
export var productSelectButton = "component-module--productSelectButton--25lJU";