// extracted by mini-css-extract-plugin
export var contactTeaserElementContainer = "component-module--contactTeaserElementContainer--jFJLv";
export var contactTeaserElementCard = "component-module--contactTeaserElementCard--OFIPa";
export var contactTeaserElementStrech = "component-module--contactTeaserElementStrech--xgB0K";
export var contactTeaserElementContentBlock = "component-module--contactTeaserElementContentBlock--+oPfA";
export var contactTeaserElementImageContainer = "component-module--contactTeaserElementImageContainer--H+Tma";
export var contactTeaserElementImageLabel = "component-module--contactTeaserElementImageLabel--EBG74";
export var contactTeaserElementImage = "component-module--contactTeaserElementImage--xwmrT";
export var contactTeaserElementHeadText = "component-module--contactTeaserElementHeadText--TLP5+";
export var contactInfo = "component-module--contactInfo--QDwAr";
export var contactTeaserlinkButton = "component-module--contactTeaserlinkButton--lYlis";