import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import Headline from '../../components/headline';
import SectionContainer from '../../components/section-container';
import StructuredTextRenderer from '../../components/structured-text-renderer'
import * as styles from "./component.module.less";
import createHtmlClass from "../../lib/helper/createHtmlClass";

export default function CallToAction(props) {
    const {
        headline,
        semanticOfHeadline,
        ctaButton,
        backgroundimage,
        background
    } = props.data

    return (
        <SectionContainer sectionClass={`${styles.callToActionSection} ${styles.fullWidthSection}`} background={background}>
            <Headline 
                className={styles.callToActionHeadline}
                semanticOfHeadline={semanticOfHeadline}
            >
                {headline}
            </Headline>

            <StructuredTextRenderer
                className={createHtmlClass([styles.callToActionButton, 'btn-dark'])}
                data={ctaButton}
                noContainer
            />

            <GatsbyImage image={getImage(backgroundimage.gatsbyImageData)} alt={backgroundimage.alt ?? ''}
                         className={styles.fullWidthImage}/>
        </SectionContainer>
    )
}
