// extracted by mini-css-extract-plugin
export var containerMediaText = "component-module--containerMediaText--rrgO6";
export var mediaTextContentSection = "component-module--mediaTextContentSection--1b4iE";
export var containerMediaTextDark = "component-module--containerMediaTextDark--DTdxy";
export var mediaTextBox = "component-module--mediaTextBox--hSB-S";
export var mediaTextHead = "component-module--mediaTextHead--hFZJX";
export var mediaTextButtonContainer = "component-module--mediaTextButtonContainer--5Gh2L";
export var mediaTextCtaButton = "component-module--mediaTextCtaButton--u29vn";
export var mediaTextSecondaryButton = "component-module--mediaTextSecondaryButton--qaNe4";
export var mediaTextButtonGreen = "component-module--mediaTextButtonGreen--usvS1";
export var mediaTextButtonOrange = "component-module--mediaTextButtonOrange--rjljl";
export var mediaTextMedia = "component-module--mediaTextMedia--EP3Ue";