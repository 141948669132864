import { isLink, isParagraph, isSpan } from 'datocms-structured-text-utils';
import React, { Fragment } from 'react'
import { renderRule } from 'react-datocms';
import * as styles from './component.module.less';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import StructuredTextRenderer from '../../../components/structured-text-renderer';
import Headline from '../../../components/headline';
import createHtmlClass from '../../../lib/helper/createHtmlClass';
import t from '../../../lib/translate'

export default function IconTeaserElement({ data, semanticOfHeadline, locale }) {
    return (
        <StructuredTextRenderer
            data={data.headlineAndLink}
            className={styles.iconTeaserElement}
            renderLinkToRecord={({ record, children, transformedMeta }) => {
                record.slug = record.slug === 'index' ? '' : record.slug;
                transformedMeta = transformedMeta || {}

                transformedMeta.className = createHtmlClass([styles.iconTeaserHeadlineLink, transformedMeta?.className || ''])
            
                return (
                    <>
                        <Link
                            to={locale ? `/${locale}/${record.slug}` : `/${record.slug}`}
                            activeStyle={{ color: "red" }}
                            {...transformedMeta}
                        >
                            {children}
                            <div className={styles.iconTeaserHoverText}>
                                {t('page.icon-teaser.hovertext')}
                            </div>
                            <div className={styles.iconTeaserImageContainer}>
                                <div className={styles.iconTeaserImage}>
                                    <GatsbyImage image={getImage(data.icon.gatsbyImageData)} alt=''/>
                                </div>
                            </div>
                        </Link>
                    </>
                );
            }}
            customRules={[
                renderRule(isParagraph, ({ children, key }) => {
                    return(
                        <Fragment key={key}>
                            {children}
                        </Fragment>
                    )
                }),
                renderRule(isLink, ({ node, children, key }) => {
                    const meta = {}

                    if (node.meta?.length) {
                        node.meta.forEach(metaItem => {
                            if (metaItem.id === 'className') {
                                meta[metaItem.id] = createHtmlClass([styles.iconTeaserHeadlineLink, metaItem.value])
                            } else {
                                meta[metaItem.id] = metaItem.value
                            }
                        })
                    }

                    meta.className ??= styles.iconTeaserHeadlineLink;

                    return(
                        <a key={key}
                            href={node.url}
                            {...meta}
                        >
                            {children}
                            <div className={styles.iconTeaserHoverText}>
                                {t('page.icon-teaser.hovertext')}
                            </div>
                            <div className={styles.iconTeaserImageContainer}>
                                <div className={styles.iconTeaserImage}>
                                    <GatsbyImage image={getImage(data.icon.gatsbyImageData)} alt=''/>
                                </div>
                            </div>
                        </a>
                    )
                }),
                renderRule(isSpan, ({ node, key }) => {
                    return (
                        <Headline className={styles.iconTeaserHeadline}
                            key={key}
                            semanticOfHeadline={semanticOfHeadline}
                        >{node.value}</Headline>
                    )
                }) 
            ]}
        />
    )
}
