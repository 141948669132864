// extracted by mini-css-extract-plugin
export var mediaTeaserElement = "component-module--mediaTeaserElement--qVpDI";
export var mediaTeaserTitle = "component-module--mediaTeaserTitle--FigI4";
export var mediaTeaserPlayButtonContainer = "component-module--mediaTeaserPlayButtonContainer---qSyE";
export var mediaTeaserPlayButton = "component-module--mediaTeaserPlayButton--jg3jN";
export var mediaTeaserThumbnail = "component-module--mediaTeaserThumbnail--8FO3c";
export var mediaPlayButton = "component-module--mediaPlayButton--k8rTy";
export var mediaTeaserModal = "component-module--mediaTeaserModal--+NEah";
export var closeButtonContainer = "component-module--closeButtonContainer--5nMMB";
export var mediaContainer = "component-module--mediaContainer--15vy7";
export var mediaTeaserVideo = "component-module--mediaTeaserVideo--iNrIF";
export var mediaTeaserModalPlayButtonContainer = "component-module--mediaTeaserModalPlayButtonContainer--75YWu";
export var mediaTeaserModalPlayButton = "component-module--mediaTeaserModalPlayButton--3c2QP";