// extracted by mini-css-extract-plugin
export var contactPersonContainer = "component-module--contactPersonContainer--XdLNE";
export var contactPersonSectionContainer = "component-module--contactPersonSectionContainer--yC1+V";
export var contactPersonImage = "component-module--contactPersonImage--EVs1v";
export var contactPersonContentWrapper = "component-module--contactPersonContentWrapper--5521g";
export var contactInfoHeadline = "component-module--contactInfoHeadline--W8dUx";
export var contactPersonInfo = "component-module--contactPersonInfo--U9545";
export var name = "component-module--name--uN0Pz";
export var contactInfoContainer = "component-module--contactInfoContainer--REG31";
export var contactInfoPhone = "component-module--contactInfoPhone--Olg-P";
export var contactInfoEmail = "component-module--contactInfoEmail--BsUAp";
export var contactInfoButtonContainer = "component-module--contactInfoButtonContainer--GthFD";