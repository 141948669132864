import React from "react";
import * as styles from "./component.module.less";
import Headline from "../../components/headline";
import { GatsbyImage } from "gatsby-plugin-image";

const ContentLinkTeaser = (props) => {
  let containerClasses = [styles.container];
  if (props.background === "dunkel") {
    containerClasses.push(styles.dark);
  } else {
    containerClasses.push(styles.light);
  }

  return (
    <section className={`${containerClasses.join(" ")} `}>
      <header>
        <Headline semanticOfHeadline={props.semanticOfHeadline} />
      </header>
      <div className={styles.teaser}>
        {props.teaser.map((teaser) => {
          return (
          <div>
            <GatsbyImage 

            />
          </div>
          );
        })}
      </div>
    </section>
  );
};

export default ContentLinkTeaser;
