import React from 'react'
import * as styles from './component.module.less'

export default function FormContainer({children}) {
    return (
        <div className={styles.formContainer}>
            {children}
        </div>
    )
}
