import React from "react"
import * as styles from "./component.module.less"
import Headline from "../../components/headline"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const HeaderHeadlineImage = (props) => {
    const {
        headline,
        semanticOfHeadline,
        mediaBackground,
        media
    } = props.data

    const headerImage = getImage(media.gatsbyImageData)
    const headerImageBackground = getImage(mediaBackground.gatsbyImageData)

    return (
        <section className={styles.headerHeadlineImage}>
            <div className={styles.headerHeadlineImageSection}>
                <Headline
                    className={styles.headline}
                    semanticOfHeadline={semanticOfHeadline}
                    accentColor={'#F9CB99'}
                >
                    {headline}
                </Headline>
                <div className={styles.headerImageBlock}>
                    <GatsbyImage
                        className={styles.headerImage}
                        image={headerImage}
                        objectFit='contain'
                        alt={media.alt ? media.alt : ''}
                    />
                </div>
            </div>
            <div className={styles.imageContainer}>
                <GatsbyImage 
                    className={styles.headerBackgroundImage}
                    image={headerImageBackground}
                    alt={mediaBackground.alt ? mediaBackground.alt : ''}
                />
            </div>
        </section>
    )

}

export default HeaderHeadlineImage;