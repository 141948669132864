import React from "react";
import * as styles from "./component.module.less";
import { GatsbyImage } from "gatsby-plugin-image";
import StructuredTextRenderer from "../../../components/structured-text-renderer";

const NewsTeaserElement = ({image, header, description, locale, slug}) => (
  <div className={styles.container}>
    <GatsbyImage
      image={image.gatsbyImageData}
      alt={image.alt}
    />
    <span>{header}</span>
    <StructuredTextRenderer data={description} />
    <a href={locale ? `/${locale}/${slug}` : `/${slug}`}>{slug}</a>
  </div>
);

export default NewsTeaserElement;
