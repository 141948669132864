// extracted by mini-css-extract-plugin
export var teaserElementContainer = "component-module--teaserElementContainer--luIjW";
export var teaserElementCard = "component-module--teaserElementCard--uCRdy";
export var teaserElementStrech = "component-module--teaserElementStrech--+QfvD";
export var teaserElementContentBlock = "component-module--teaserElementContentBlock--8EmtE";
export var teaserElementImageContainer = "component-module--teaserElementImageContainer--cjbty";
export var teaserElementImageLabel = "component-module--teaserElementImageLabel--Tn85s";
export var teaserElementImage = "component-module--teaserElementImage--gGEG+";
export var teaserElementHeadText = "component-module--teaserElementHeadText--S6-mz";
export var teaserElementText = "component-module--teaserElementText--IG0rO";
export var linkButton = "component-module--linkButton--8K3CQ";