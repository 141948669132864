import React, { Fragment } from "react";
import * as styles from "./component.module.less";
import t from "../../../lib/translate";
import StructuredTextRenderer from "../../../components/structured-text-renderer";
import { renderRule } from "react-datocms";
import { isParagraph } from "datocms-structured-text-utils";
import createHtmlClass from "../../../lib/helper/createHtmlClass";

export default function PlanProduct({
	data: {
		type,
		name,
		label,
		// producttype,
		promotionPeriode,
		promotionPrice,
		priceNormal,
		priceInclTax,
		namePlan,
		download,
		upload,
		planFeature = [],
		initialContractTerm,
		productFlyer,
	},
	linkPlanOverview,
}) {
	const isBroadbandProduct = type === "DatoCmsBroadbandProduct";

	const [planNumber, planUnit] = isBroadbandProduct
		? namePlan.split(" ")
		: [null, null];

	const filename = `${t("page.broadband-product.download-link")}-${name.replace(
		" ",
		"-"
	)}`;

	const productLabelClasses = [
		styles.productLabel,
		promotionPrice ? styles.productLabelPromotion : "",
	];

	return (
		<div className={styles.product}>
			<div className={styles.productHeaderContainer}>
				{(label || promotionPrice) && (
					<span className={createHtmlClass(productLabelClasses)}>
						{promotionPrice
							? t("page.broadband-product.promotion-label")
							: label}
					</span>
				)}

				<p className={styles.productName}>{name}</p>

				{isBroadbandProduct && (
					<p className={styles.productPlanName}>
						<span>{planNumber}</span>

						{planUnit}
					</p>
				)}
			</div>

			<div
				className={createHtmlClass([
					styles.productDetailsContainer,
					isBroadbandProduct ? "" : styles.wlanPlanDetails,
				])}
			>
				<p
					className={createHtmlClass([
						styles.productPrice,
						promotionPrice ? styles.productPriceDisabled : "",
					])}
				>
					{priceNormal} €
				</p>

				{promotionPrice && (
					<>
						<p className={styles.productPromotionPrice}>
							{`${t(
								"page.broadband-product.promotion-price-label"
							)} ${promotionPrice}€`}
						</p>

						<p className={styles.productPromotionDuration}>
							{promotionPeriode}
						</p>
					</>
				)}

				{priceInclTax && (
					<p className={styles.productTaxHint}>
						{t("page.broadband-product.taxhint")}
					</p>
				)}

				{(download || upload || planFeature.length || initialContractTerm) && (
					<ul className={styles.productFeatures}>
						{download && (
							<li>
								<span>{`${download} ${t(
									"page.broadband-product.downloadspeed"
								)}`}</span>
							</li>
						)}

						{upload && (
							<li>
								<span>{`${upload} ${t(
									"page.broadband-product.uploadspeed"
								)}`}</span>
							</li>
						)}

						{planFeature.length &&
							planFeature.map((feature) => (
								<li key={feature.id}>
									<span>
										<StructuredTextRenderer
											data={feature.feature}
											noContainer={true}
											customRules={[
												renderRule(isParagraph, ({ children, key }) => {
													return <Fragment key={key}>{children}</Fragment>;
												}),
											]}
										/>
									</span>
								</li>
							))}

						{initialContractTerm && (
							<li>
								<span>{`${initialContractTerm} ${t(
									"page.broadband-product.contractduration"
								)}`}</span>
							</li>
						)}
					</ul>
				)}
			</div>
			<div
				className={createHtmlClass([
					styles.productSelectButtonContainer,
					isBroadbandProduct ? "" : styles.wlanPlanButtonContainer,
				])}
			>
				<a
					className={styles.productDownloadLink}
					href={productFlyer.url}
					download={filename}
					target="_blank"
				>
					{isBroadbandProduct
						? t("page.broadband-product.download-link")
						: t("page.wlan-plan.download-link")}
				</a>

				{isBroadbandProduct && (
					<a
						className={createHtmlClass([
							styles.productSelectButton,
							"btn-dark",
						])}
						href={linkPlanOverview}
                        target="_blank"
					>
						{t`page.broadband-product.button.select-plan`}
					</a>
				)}
			</div>
		</div>
	);
}
