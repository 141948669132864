import React from "react";
import { navigate } from "gatsby";

import Headline from "../../headline";
import FormContainer from "../form-components/form-container";

import t from "../../../lib/translate";

import * as styles from "./component.module.less";

export default function SuccessPage() {
    return (
			<div className={styles.successPage}>
				<div className={styles.successPageContentBlock}>
					<FormContainer>
						<div className={styles.successPageContent}>
							<Headline semanticOfHeadline="H3">
								{t("page.form-success.header")}
							</Headline>
						</div>
					</FormContainer>
				</div>
			</div>
		);
}
