import React from "react";
// import * as styles from "./component.module.less"
import NewsTeaser from "../../content-elements/news-teaser";
import Quote from "../../content-elements/quote";
import TrustLogo from "../../content-elements/trust-logo";
import MediaSlider from "../../content-elements/media-slider";
import ContentLinkTeaser from "../../content-elements/content-link-teaser";
import Text from "../../content-elements/2.01-text";
import TextAdvantage from "../../content-elements/2.01-text/2.01.1-text-advantage";
import MediaText from "../../content-elements/2.02-media-text";
import AxpIframeEmbed from "../../content-elements/2.03-axp-iframe-embed";
import MediaTextSlider from "../../content-elements/2.05-media-text-slider";
import Teaser from "../../content-elements/2.04-content-teaser";
import PlanOverview from "../../content-elements/2.06-plan-overview";
import Faq from "../../content-elements/2.07-faq";
import Download from "../../content-elements/2.08-download";
import CallToAction from "../../content-elements/2.09-call-to-action";
import ContactPersonBlock from "../../content-elements/2.10-contact-person-block";

const ContentElementRenderer = (props) => {
  const items = props.items || [];

  return (
    <>
      <>{props.children}</>
      {items.map((contentElement, i) => {
        switch (
					(contentElement &&
						contentElement.internal &&
						contentElement.internal.type) ||
					null
				) {
					case "DatoCmsText":
						return (
							<Text
								key={i}
								locale={props.locale}
								data={contentElement}
								config={props.config}
							/>
						);

					case "DatoCmsTextAdvantage":
						return (
							<TextAdvantage
								key={i}
								locale={props.locale}
								data={contentElement}
							/>
						);

					case "DatoCmsMediaText":
						return (
							<MediaText key={i} locale={props.locale} data={contentElement} />
						);

					case "DatoCmsAxpIframeEmbed":
						return (
							<AxpIframeEmbed
								key={i}
								locale={props.locale}
								axpEmbed={contentElement.axpEmbed}
							/>
						);

					case "DatoCmsContentTeaser":
					case "DatoCmsServiceContactTeaser":
					case "DatoCmsContentIconTeaser":
					case "DatoCmsContentMediaTeaser":
						return (
							<Teaser
								key={i}
								locale={props.locale}
								data={contentElement}
								type={contentElement.internal.type}
							/>
						);

					case "DatoCmsMediaTextSlider":
						return (
							<MediaTextSlider
								key={i}
								locale={props.locale}
								data={contentElement}
							/>
						);

					case "DatoCmsPlanOverview":
						return (
							<PlanOverview
								key={i}
								locale={props.locale}
								data={contentElement}
								linkPlanOverview={props.config.linkPlanOverview}
							/>
						);

					case "DatoCmsFaqBlock":
						return <Faq key={i} locale={props.locale} data={contentElement} />;

					case "DatoCmsDownloadBlock":
						return (
							<Download key={i} locale={props.locale} data={contentElement} />
						);

					case "DatoCmsCta":
						return (
							<CallToAction
								key={i}
								locale={props.locale}
								data={contentElement}
							/>
						);

					case "DatoCmsContactPersonBlock":
						return (
							<ContactPersonBlock
								key={i}
								locale={props.locale}
								data={contentElement}
								config={props.config}
							/>
						);

					case "DatoCmsNewsTeaser":
						return (
							<NewsTeaser
								key={i}
								locale={props.locale}
								tagline={contentElement.tagline}
								headline={contentElement.headline}
								semanticOfHeadline={contentElement.semanticOfHeadline}
								newsCategory={contentElement.newsCategory}
								semanticOfNewsHeadline={contentElement.semanticOfNewsHeadline}
								background={contentElement.background}
							/>
						);

					case "DatoCmsQuote":
						return (
							<Quote
								key={i}
								locale={props.locale}
								quote={contentElement.quote}
								image={contentElement.image}
								name={contentElement.name}
								jobPosition={contentElement.jobPosition}
								background={contentElement.background}
							/>
						);

					case "DatoCmsTrustLogo":
						return (
							<TrustLogo
								key={i}
								locale={props.locale}
								tagline={contentElement.tagline}
								headline={contentElement.headline}
								semanticOfHeadline={contentElement.semanticOfHeadline}
								logos={contentElement.logo}
								background={contentElement.background}
							/>
						);

					case "DatoCmsMediaSlider":
						return (
							<MediaSlider
								key={i}
								locale={props.locale}
								tagline={contentElement.tagline}
								headline={contentElement.headline}
								semanticOfHeadline={contentElement.semanticOfHeadline}
								images={contentElement.images}
								background={contentElement.background}
							/>
						);

					case "DatoCmsGlobalBlock":
						return (
							<ContentElementRenderer
								key={contentElement.id}
								locale={props.locale}
								items={contentElement?.globalBlock?.contentBlock ?? []}
							/>
						);

					case "DatoCmsContentLinkTeaser":
						return (
							<ContentLinkTeaser
								key={i}
								locale={props.locale}
								tagline={contentElement.tagline}
								headline={contentElement.headline}
								semanticOfHeadline={contentElement.semanticOfHeadline}
								semanticOfTeaserHeadline={
									contentElement.semanticOfTeaserHeadline
								}
								teaser={contentElement.teaser}
							/>
						);

					default:
						return (
							<div key={i}>
								unknown content element:{" "}
								{(contentElement &&
									contentElement.internal &&
									contentElement.internal.type) ||
									"unknown type"}
							</div>
						);
				}
      })}
    </>
  );
};

export default ContentElementRenderer;
