// extracted by mini-css-extract-plugin
export var container = "component-module--container--SsWyp";
export var slider = "component-module--slider--aWzx8";
export var media = "component-module--media--jcUvp";
export var image = "component-module--image--v1nRy";
export var h1 = "component-module--h1--J+GI2";
export var h2 = "component-module--h2--ykV+Q";
export var h3 = "component-module--h3--AxI46";
export var h4 = "component-module--h4--qTsfx";
export var h5 = "component-module--h5--nLnf+";
export var dark = "component-module--dark--w1Z4t";
export var light = "component-module--light---Rq7F";
export var buttonLight = "component-module--buttonLight--Fj3AD";
export var buttonDark = "component-module--buttonDark--ZnRVS";