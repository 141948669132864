import React from "react"

import * as styles from './component.module.less'

export default class extends React.Component {

  groups;

  constructor(props) {
    super(props);

    this.groups = props.groups || [];

    this.state = this.groups.reduce((c, group) => {
      if (c[group.uid] !== undefined) return c;
      c[group.uid] = props.default || false;
      return c;
    }, (props.values || {}));
  }

  render() {
    return (
        <ol className={styles.groups}>
          {this.groups.map((group, i) => (
              <li key={i}>
                <div className={styles.checkboxWrapper}>
                    <input
                        id={'swoecc-g' + i}
                        name={'swoecc-g' + i}
                        checked={this.state[group.uid]}
                        onChange={e => {
                        const newState = this.state;
                        newState[group.uid] = e.target.checked;
                        this.setState(newState);
                        }}
                        type="checkbox"
                    />
                    
                    <label>{group.name}</label>
                </div>
                {group.description &&
                    <div className={styles.desc} dangerouslySetInnerHTML={{__html: group.description}} />
                }
              </li>
          ))}
        </ol>
    );
  }
}
