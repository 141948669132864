// @see https://github.com/bluele/gforms

import t from "../../translate";

export default class PDFGenerator {
	baseUrl;
	locale;

	/**
	 * constructor
	 *
	 * @param {string} baseUrl
	 * @param {string} locale
	 */
	constructor(baseUrl, locale = "de") {
		this.baseUrl = baseUrl;
		this.locale = locale;
	}

	/**
	 *
	 * @param {string} name
	 * @param {{}} data
	 * @returns {Promise<unknown>}
	 */
	generate(data) {
		return new Promise((resolve, reject) => {
			fetch(`${this.baseUrl}/generate`, {
				method: "POST",
				mode: "cors",
				headers: {
					"Content-Type": "application/json",
					// 'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: JSON.stringify(data),
			})
				.then((response) => {
					if (![200, 201, 422].includes(response.status)) {
						throw new Error(
							`PDF generator service respondend with status code: ${response.status}`
						);
					}

					return response;
				})
				.then((response) => response.json())
				.then((result) => {
					if (result == null) {
						return null;
					}

					for (const name of Object.keys(result)) {
						if (name === "id" || name === "secret") {
							break;
						}

						let msgs = result[name].filter((msg) => msg.lang === this.locale);

						if (msgs.length === 0) {
							msgs = result[name];
						}

						result[name] = msgs.map((msg) => msg.text);
					}

					resolve(result);
				})
				.catch((reason) => {
					reject(reason);
				});
		});
	}
}
