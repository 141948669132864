import React, { useState } from "react";
import * as styles from "./component.module.less";
import t from "../../lib/translate";
import Headline from "../../components/headline";
import { Link, navigate } from "gatsby";
import createHtmlClass from "../../lib/helper/createHtmlClass";
import FormContainer from "../../components/forms/form-components/form-container";
import FormContext from "./FormContext";

const FormLayout = ({ locale, config, children, formId }) => {
	const department = config.department;

	const [formSubmitted, setFormSubmitted] = useState(false);

	const contextValue = {
		formSubmitted,
		setFormSubmitted,
	};

	return (
		<FormContext.Provider value={contextValue}>
			<div className={styles.wrapper}>
				<div className={styles.navDesktop}>
					<div className={styles.navBarDesktop}>
						<Link
							to={locale ? `/${locale}/` : "/"}
							className={`${styles.navBarLogo} logo-md`}
						/>

						<div className={styles.spacer} />
					</div>
				</div>

				<main className={styles.mainContent}>
					<aside className={styles.contactBoxWrapper}>
						<div className={styles.contactBox}>
							<Headline semanticOfHeadline="H5 Style">{t`page.form-contact-info.header`}</Headline>

							<p>
								<strong>{department.name}</strong>
								<br />
								{department.street} {department.number}
								<br />
								{department.zip} {department.place}
								<br />
							</p>

							<p>
								{department.phone && (
									<>
										<span>{t`page.form-contact-info.phone`}</span>
										<br />
										<a href={`tel:${department.phone}`}>
											<strong>{department.phone}</strong>
										</a>
										<br />
									</>
								)}
							</p>

							<p>
								<span>{t`page.form-contact-info.mail`}</span>
								<br />
								<a href={`mailto:${department.email}`}>
									<strong>{department.email}</strong>
								</a>
								<br />
							</p>

							<p>
								{department.fax && (
									<>
										<span>{t`page.form-contact-info.fax`}</span>
										<br />
										<a href={`fax:${department.fax}`}>
											<strong>{department.fax}</strong>
										</a>
										<br />
									</>
								)}
							</p>
						</div>
					</aside>

					<div
						className={createHtmlClass([
							styles.formWrapper,
							formSubmitted ? styles.formSuccess : "",
						])}
					>
						<FormContainer>{children}</FormContainer>

						<footer className={styles.formButtonsWrapper}>
							<div className={styles.formButtons}>
								<button
									className={createHtmlClass([
										styles.formBackButton,
										"btn-dark",
									])}
									onClick={() => navigate(-1)}
								>
									{t`page.forms.back-button`}
								</button>

								{!formSubmitted && (
									<button
										form={formId}
										className={createHtmlClass([
											styles.formSubmitButton,
											"btn-dark",
										])}
										type="submit"
									>
										{formId === "form_cancellation"
											? t`page.cancellation-forms.submit-button.label`
											: t`page.forms.submit-button`}
									</button>
								)}
							</div>
						</footer>
					</div>
				</main>
			</div>
		</FormContext.Provider>
	);
};

export default FormLayout;
