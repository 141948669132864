// extracted by mini-css-extract-plugin
export var downloadSection = "component-module--downloadSection--UAkJh";
export var downloadSectionHeadline = "component-module--downloadSectionHeadline--hNaP8";
export var downloadText = "component-module--downloadText--Y0v57";
export var downloadGroup = "component-module--downloadGroup--MJ5ey";
export var downloadGroupHeadline = "component-module--downloadGroupHeadline--HAWqm";
export var downloadLinkList = "component-module--downloadLinkList--3kEwh";
export var downloadLink = "component-module--downloadLink--689GL";
export var downloadLinkIcon = "component-module--downloadLinkIcon--NchHG";
export var file = "component-module--file--eAqKt";
export var external = "component-module--external--ZVYr5";