import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Headline from "../../headline";
import FormField from "../form-components/form-field";
import SuccessPage from "../SuccessPage";

import FormService from "../../../lib/services/form";
import PDFGenerator from "../../../lib/services/pdf-generator";
import FormContext from "../../../layouts/form-layout/FormContext";

import t from "../../../lib/translate";

export default function CancellationForm({ formId }) {
	const fromService = new FormService(process.env.GATSBY_API_FORM_URL);
	const pdfGenService = new PDFGenerator(process.env.GATSBY_API_PDFGEN_URL);

	const { formSubmitted, setFormSubmitted } = useContext(FormContext);

	const [errorMessage, setErrorMessage] = useState("");

	async function submitForm(values) {
		const formData = JSON.parse(JSON.stringify(values));

		formData.gdpr = formData.gdpr === true ? 1 : 0;

		if (!formData.product) {
			setErrorMessage(t("page.form.general.error"));
			return;
		}
        
        const formEndPoint =
            formData.product === "3" ? "cancel-internet" : "cancel-utility";

		const { product } = formData;
        let resolvedProduct = '';
        
		switch (product) {
			case "1":
				resolvedProduct = t("page.cancellation-form.label.product.energy");
				break;
			case "2":
				resolvedProduct = t("page.cancellation-form.label.product.gas");
				break;
			case "3":
				resolvedProduct = t("page.cancellation-form.label.product.internet");
				break;
			default:
				resolvedProduct = t("page.cancellation-form.placeholder.product");
		}

        const requestData = { ...formData, product: resolvedProduct };


		try {
			const res = await pdfGenService.generate(requestData);

			if (res.id) {
				requestData.id = res.id;
				requestData.secret = res.secret;

				const result = await fromService.submit(formEndPoint, requestData);

				if (result) {
					setErrorMessage(t("page.form.general.error"));
				} else {
					setFormSubmitted(true);
				}
			} else {
				setErrorMessage(t("page.form.general.error"));
			}
		} catch (error) {
			console.error(error);
			setErrorMessage(t("page.form.general.error"));
		}

		window[`scrollTo`]({ top: 0, behavior: `smooth` });
	}

	if (formSubmitted) return <SuccessPage />;

	const validationSchema = Yup.object({
		salutation: Yup.string()
			.required(t`page.cancellation-form.select.error-message`)
			.oneOf(
				t("page.cancellation-form.label.salutation.values").split(","),
				t`page.cancellation-form.select.error-message`
			),
		firstname: Yup.string(),
		lastname: Yup.string().required(t`page.cancellation-form.error-message`),
		street: Yup.string().required(t`page.cancellation-form.error-message`),
		number: Yup.string().required(t`page.cancellation-form.error-message`),
		city: Yup.string().required(t`page.cancellation-form.error-message`),
		zip: Yup.string().required(t`page.cancellation-form.error-message`),
		product: Yup.string()
			.required(t`page.cancellation-form.select.error-message`)
			.oneOf(["1", "2", "3"], t`page.cancellation-form.select.error-message`),
		counter_number: Yup.string(),
		customer_number: Yup.string().required(
			t`page.cancellation-form.error-message`
		),
		termination: Yup.string()
			.required(t`page.cancellation-form.select.error-message`)
			.oneOf(
				t("page.cancellation-form.label.cancellation.values").split(","),
				t`page.cancellation-form.select.error-message`
			),
		reason_for_termination: Yup.string()
			.required(t`page.cancellation-form.select.error-message`)
			.oneOf(
				t("page.cancellation-form.label.reason_of_cancellation.values").split(
					","
				),
				t`page.cancellation-form.select.error-message`
			),
		requested_termination_date: Yup.string().required(
			t`page.cancellation-form.error-message`
		),
		email: Yup.string()
			.email(t`page.cancellation-form.invalid.email`)
			.required(t`page.cancellation-form.error-message`),
		message: Yup.string().max(
			500,
			t`page.cancellation-forms.message-limit.error-message`
		),
		gdpr: Yup.boolean().required(t`page.cancellation-form.error-message`),
	});

	return (
		<>
			<Formik
				initialValues={{
					salutation: "",
					firstname: "",
					lastname: "",
					street: "",
					number: "",
					city: "",
					zip: "",
					product: "",
					counter_number: "",
					customer_number: "",
					termination: "",
					reason_for_termination: "",
					requested_termination_date: "",
					email: "",
					message: "",
					gdpr: "",
				}}
				validationSchema={validationSchema}
				onSubmit={submitForm}
			>
				<Form id={formId}>
					<legend>
						<Headline semanticOfHeadline="H1">
							{t("page.cancellation-form.header")}
						</Headline>
						{t`page.cancellation-form.subheader`}
					</legend>

					<span className="errorMsg">{errorMessage}</span>

					<FormField
						type="select"
						name="salutation"
						label={t("page.cancellation-form.label.salutation")}
						placeholder={t("page.cancellation-form.placeholder.salutation")}
						options={[
							{
								name: t("page.cancellation-form.placeholder.salutation"),
							},
							...t("page.cancellation-form.label.salutation.values")
								.split(",")
								.map((option) => ({ name: option, value: option })),
						]}
						required
					/>

					<FormField
						type="text"
						name="firstname"
						label={t`page.cancellation-form.label.firstname`}
						placeholder={t`page.cancellation-form.placeholder.firstname`}
					/>

					<FormField
						type="text"
						name="lastname"
						label={t`page.cancellation-form.label.lastname`}
						placeholder={t`page.cancellation-form.placeholder.lastname`}
						required
					/>

					<FormField
						type="text"
						name="street"
						label={t`page.cancellation-form.label.street`}
						placeholder={""}
						required
					/>

					<FormField
						type="text"
						name="number"
						label={t`page.cancellation-form.label.number`}
						placeholder={""}
						required
					/>
					<FormField
						type="text"
						name="city"
						label={t`page.cancellation-form.label.city`}
						placeholder={""}
						required
					/>

					<FormField
						type="text"
						name="zip"
						label={t`page.cancellation-form.label.zip`}
						placeholder={""}
						required
					/>

					<FormField
						type="select"
						name="product"
						label={t("page.cancellation-form.label.product")}
						placeholder={t("page.cancellation-form.placeholder.product")}
						options={[
							{
								name: t("page.cancellation-form.placeholder.product"),
							},
							{
								name: t("page.cancellation-form.label.product.energy"),
								value: 1,
							},
							{
								name: t("page.cancellation-form.label.product.gas"),
								value: 2,
							},
							{
								name: t("page.cancellation-form.label.product.internet"),
								value: 3,
							},
						]}
						required
					/>

					<FormField
						type="text"
						name="counter_number"
						label={t`page.cancellation-form.label.counter_number`}
						placeholder={""}
					/>

					<FormField
						type="text"
						name="customer_number"
						label={t`page.cancellation-form.label.customer_number`}
						placeholder={""}
						required
					/>

					<FormField
						type="select"
						name="termination"
						label={t("page.cancellation-form.label.cancellation")}
						placeholder={t("page.cancellation-form.placeholder.cancellation")}
						options={[
							{
								name: t("page.cancellation-form.placeholder.cancellation"),
							},
							...t("page.cancellation-form.label.cancellation.values")
								.split(",")
								.map((option) => ({ name: option, value: option })),
						]}
						required
					/>

					<FormField
						type="select"
						name="reason_for_termination"
						label={t("page.cancellation-form.label.reason_of_cancellation")}
						placeholder={t(
							"page.cancellation-form.placeholder.reason_of_cancellation"
						)}
						options={[
							{
								name: t(
									"page.cancellation-form.placeholder.reason_of_cancellation"
								),
							},
							...t("page.cancellation-form.label.reason_of_cancellation.values")
								.split(",")
								.map((option) => {
									return { name: option, value: option };
								}),
						]}
						required
					/>

					<FormField
						type="date"
						name="requested_termination_date"
						label={t`page.cancellation-form.label.date_of_cancellation`}
						placeholder={""}
						required
					/>

					<FormField
						type="text"
						name="email"
						label={t`page.cancellation-form.label.email`}
						placeholder={t`page.cancellation-form.placeholder.email`}
						required
					/>

					<FormField
						type="textarea"
						name="message"
						label={t`page.cancellation-form.label.message`}
						rows="10"
						placeholder={t`page.cancellation-form.placeholder.message`}
					/>

					<FormField
						type="checkbox"
						name="gdpr"
						label={t`page.cancellation-form.label.gdpr`}
						inputFirst
						required
					/>
				</Form>
			</Formik>
		</>
	);
}
