// extracted by mini-css-extract-plugin
export var wrapper = "component-module--wrapper--fu7UK";
export var navDesktop = "component-module--navDesktop--2XG5L";
export var navBarDesktop = "component-module--navBarDesktop--qsTR+";
export var navBarLogo = "component-module--navBarLogo--2sciU";
export var spacer = "component-module--spacer---HDu6";
export var mainContent = "component-module--mainContent--Dwa0Q";
export var contactBoxWrapper = "component-module--contactBoxWrapper--FINIU";
export var contactBox = "component-module--contactBox--VqyTu";
export var formWrapper = "component-module--formWrapper--Jv5tU";
export var formSuccess = "component-module--formSuccess--xm1gw";
export var formButtonsWrapper = "component-module--formButtonsWrapper--ZSF3B";
export var formButtons = "component-module--formButtons--GqiMs";
export var formBackButton = "component-module--formBackButton--EuPgv";
export var formSubmitButton = "component-module--formSubmitButton--5y9Hd";