import React from 'react'
import * as styles from './component.module.less';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Headline from '../../../components/headline';
import StructuredTextRenderer from '../../../components/structured-text-renderer';
import {ContactInfo} from '../../../components/contact-info';

export default function ContactTeaserElement(props) {
    const {
        headline,
        semanticOfHeadline,
        media,
        contactInfo,
        link
    } = props.data;

    const teaserImage = getImage(media.gatsbyImageData)

    return (
        <div className={styles.contactTeaserElementContainer}>
                <div className={styles.contactTeaserElementImageContainer}>
                    <GatsbyImage className={styles.contactTeaserElementImage} image={teaserImage} alt={media.alt}/>
                </div>

            <div className={styles.contactTeaserElementContentBlock}>
                <Headline
                    className={styles.contactTeaserElementHeadText}
                    semanticOfHeadline={semanticOfHeadline}
                >
                    {headline}
                </Headline>

                <ContactInfo className={styles.contactInfo} contactInfo={contactInfo}/>

                <StructuredTextRenderer 
                    className={styles.contactTeaserlinkButton}
                    data={link}
                />
            </div>
        </div>
    );
}
