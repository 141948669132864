import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Headline from "../../headline";
import FormField from "../form-components/form-field";
import FormService from "../../../lib/services/form";
import SuccessPage from "../SuccessPage";
import FormContext from "../../../layouts/form-layout/FormContext";

import t from "../../../lib/translate";

export default function ContactForm({ formId }) {
	const fromService = new FormService(process.env.GATSBY_API_FORM_URL);

	const { formSubmitted, setFormSubmitted } = useContext(FormContext);

    const [errorMessage, setErrorMessage] = useState("");

	async function submitForm(values) {
		const formData = JSON.parse(JSON.stringify(values));
		formData.gdpr = formData.gdpr === true ? 1 : 0;

		try {
			const result = await fromService.submit("contact", formData);

			if (result) {
				setErrorMessage(t("page.form.general.error"));
			} else {
				setFormSubmitted(true);
			}
		} catch (error) {
			console.error(error);
			setErrorMessage(t("page.form.general.error"));
		}

		window[`scrollTo`]({ top: 0, behavior: `smooth` });
	}

	if (formSubmitted) return <SuccessPage />;

	return (
		<>
			<Formik
				initialValues={{
					salutation: "",
					firstName: "",
					lastName: "",
					email: "",
					phone: "",
					message: "",
					gdpr: "",
				}}
				validationSchema={Yup.object({
					salutation: Yup.string()
						.required(t`page.contact-form.error-message`)
						.oneOf(
							t("page.contact-form.label.salutation.values").split(","),
							t`page.contact-form.error-message`
						),
					firstName: Yup.string(),
					lastName: Yup.string().required(t`page.contact-form.error-message`),
					email: Yup.string()
						.email(t`page.contact-form.invalid.email`)
						.required(t`page.contact-form.error-message`),
					phone: Yup.string(),
					message: Yup.string().required(t`page.contact-form.error-message`),
					gdpr: Yup.bool().required(t`page.contact-form.error-message`),
					// invalidCheck: Yup.boolean().oneOf([true], t`validation_err_required`).required(t`validation_err_required`),
				})}
				onSubmit={submitForm}
			>
				<Form id={formId}>
					<legend>
						<Headline semanticOfHeadline="H1">
							{t("page.contact-form.header")}
						</Headline>
						{t`page.contact-form.subheader`}
					</legend>

					<span className="errorMsg">{errorMessage}</span>

					<FormField
						type="select"
						name="salutation"
						label={t("page.contact-form.label.salutation")}
						placeholder={t("page.contact-form.placeholder.salutation")}
						options={[
							{
								name: t("page.cancellation-form.placeholder.salutation"),
							},
							...t("page.contact-form.label.salutation.values")
								.split(",")
								.map((option) => ({ name: option, value: option })),
						]}
						required
					/>

					<FormField
						type="text"
						name="firstName"
						label={t`page.contact-form.label.firstname`}
						placeholder={t`page.contact-form.placeholder.firstname`}
					/>

					<FormField
						type="text"
						name="lastName"
						label={t`page.contact-form.label.lastname`}
						placeholder={t`page.contact-form.placeholder.lastname`}
						required
					/>

					<FormField
						type="text"
						name="email"
						label={t`page.contact-form.label.email`}
						placeholder={t`page.contact-form.placeholder.email`}
						required
					/>

					<FormField
						type="text"
						name="phone"
						label={t`page.contact-form.label.phone`}
						placeholder={t`page.contact-form.placeholder.phone`}
					/>

					<FormField
						type="textarea"
						name="message"
						label={t`page.contact-form.label.message`}
						rows="10"
						placeholder={t`page.contact-form.placeholder.message`}
						required
					/>

					<FormField
						type="checkbox"
						name="gdpr"
						label={t`page.contact-form.label.gdpr`}
						inputFirst
						required
					/>
				</Form>
			</Formik>
		</>
	);
}
