import React from 'react'
import * as styles from './component.module.less'
import { Field } from 'formik'

export default function RadioGroupInput(props) {
    const {
        className,
        name,
        options
    } = props

    return (
        <div role='group' id={name} className={styles.radioGroup}>
            {options.map((option, key) => (
                <label key={`${option.label}-${key}`}>
                    <Field className={className} type='radio' name={name} value={option.value}/>
                    {option.label}
                </label>
            ))}
        </div>
    )
}
