import React from 'react'
import * as styles from './component.module.less'
import {
    ErrorMessage,
} from 'formik';
import TextInput from './text-input';
import SelectInput from './select-input';
import TextAreaInput from './textarea-input';
import CheckboxInput from './checkbox-input';
import createHtmlClass from '../../../../lib/helper/createHtmlClass';
import RadioGroupInput from './radiogroup-input';
import DateInput from './date-input';

const fields = {
    text: TextInput,
    select: SelectInput,
    textarea: TextAreaInput,
    checkbox: CheckboxInput,
    radiogroup: RadioGroupInput,
    date: DateInput,
}

export default function FormField(props) {
    const {
        labelClass,
        className,
        name,
        label,
        type,
        inputFirst = false,
        required = false,
        placeholder,
        options,
        rows
    } = props

    const InputField = fields[type]

    return (
        <div className={createHtmlClass([
            styles.field,
            type === 'checkbox' ? styles.checkBoxField : ''
        ])}>
            {(label && !inputFirst) &&
                <label className={createHtmlClass([labelClass, styles.inputLabel])} htmlFor={name}>
                    {label} {required && <span>*</span>}
                </label>
            }

            <InputField 
                className={createHtmlClass([className, styles.inputField])}
                name={name}
                placeholder={placeholder}
                options={options}
                rows={rows}
            />

            {(label && inputFirst) &&
                <label className={labelClass} htmlFor={name}>
                    {label} {required && <span>*</span>}
                </label>
            }

            <ErrorMessage name={name}>
                { (msg) => <p className={styles.invalidText}>{msg}</p>}
            </ErrorMessage>
        </div>
    )
}
