import React from "react";
import * as styles from "./component.module.less";
import Headline from "../../components/headline";
import SectionContainer from "../../components/section-container";
import StructuredTextRenderer from "../../components/structured-text-renderer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import createHtmlClass from "../../lib/helper/createHtmlClass";

function MediaText(props) {
  const {
    headline, 
    semanticOfHeadline,
    subheadline,
    ctaColor,
    ctaButton,
    secundarButton,
    media,
    mediaAlignRight,
    background,
  } = props.data;

  const buttonColorClass = ( ctaColor === 'orange' ? styles.mediaTextButtonOrange : styles.mediaTextButtonGreen )
  const containerColorClass = ( background === 'dunkell' ? styles.containerMediaTextDark : '')

  return (
    // The styling for the media allignment and the section background will be handled in the <SectionContainer>
    <SectionContainer 
      className={createHtmlClass([styles.containerMediaText, containerColorClass])}
      sectionClass={styles.mediaTextContentSection}
      background={background}
      mediaAlignRight={mediaAlignRight}
    >
      <figure className={styles.mediaTextMedia}>
        {media.isImage && (
            <GatsbyImage
                image={getImage(media.gatsbyImageData)}
                title={media.title}
                alt={media.alt ? media.alt : ''}
            />
        )}
        {media?.video && (
            <video
                src={media.video.mp4Url}
                controls={true}
                autoPlay={true}
            >
              <track kind="captions" />
            </video>
        )}
      </figure>
      
      <div className={styles.mediaTextBox}>
        <div className={styles.mediaTextHead}>
          <Headline semanticOfHeadline={semanticOfHeadline}>{headline}</Headline>
          <StructuredTextRenderer data={subheadline} />
        </div>

        <div className={`${styles.mediaTextButtonContainer} ${buttonColorClass}`}>
          <StructuredTextRenderer
            data={ctaButton}
            className={styles.mediaTextCtaButton} />
          <StructuredTextRenderer
            data={secundarButton}
            className={styles.mediaTextSecondaryButton}
          />
        </div>
      </div>
    </SectionContainer>
  );
}

export default MediaText;
