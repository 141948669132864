import React from "react"

import Groups from "../groups";

import * as styles from "./component.module.less"

export default class extends React.Component {

    onChoose;
    groupsRef;
    selfRef;

    constructor(props) {
        super(props);

        this.selfRef = React.createRef();
        this.groupsRef = React.createRef();
        this.onChoose = props.onChoose || (() => {
        });

        this.state = {
            visible: props.visible,
            settings: false,
        };

        this.setVisible = this.setVisible.bind(this);

        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onAcceptAllClick = this.onAcceptAllClick.bind(this);
        this.onDeclineClick = this.onDeclineClick.bind(this);
        this.onAcceptSelectionClick = this.onAcceptSelectionClick.bind(this);
    }

    setVisible() {
        this.setState({
            visible: true,
        }, () => {
            if (!this.selfRef.current) return;
            this.selfRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        });
    }

    close() {
        this.setState({
            visible: false,
            settings: false,
        });
    }

    onSettingsClick() {
        const oldState = this.state;
        this.setState({
            settings: !oldState.settings,
        });
    }

    onAcceptAllClick() {
        this.onChoose(true, null);
        this.close();
    }

    onDeclineClick() {
        this.onChoose(false, null);
        this.close();
    }

    onAcceptSelectionClick() {
        this.onChoose(true, this.groupsRef.current.state);
        this.close();
    }

    isSettingsOpen() {
        if ((this.props.groups || []).length === 0) return false;
        return this.state.settings === true;
    }

    render() {
        if (this.state.visible === false) return null;
        return (
            <div className={styles.cookieConsentContent}>

                <div className={styles.cookieConsent} ref={this.selfRef}>
                    <div className={styles.head}>
                        {this.props.headline && this.props.headline}
                    </div>
                    {this.props.intro &&
                    <div className={styles.txt} dangerouslySetInnerHTML={{__html: this.props.intro}}/>
                    }

                    {(this.props.groups || []).length > 0 &&
                    <>
                        <button className={`${styles.nav}${this.state.settings ? ' ' + styles.act : ''}`}
                                onClick={this.onSettingsClick}>
                            <span>{this.props.settingsLabel || 'Settings'}</span>
                        </button>

                        {this.isSettingsOpen() &&
                        <Groups
                            ref={this.groupsRef}
                            groups={this.props.groups}
                            default={this.props.default}
                            values={this.props.groupsDefault}
                        />
                        }
                    </>
                    }

                    <div className={styles.actions}>
                        {this.props.imprintPage &&
                        <a href={'/' + this.props.imprintPage.slug + '/?cc=0'}
                           target="_blank" rel="noreferrer">
                            {this.props.imprintPage.name}
                        </a>
                        }

                        <div className={styles.buttonWrap}>
                            {this.isSettingsOpen() && (
                                <>
                                    <button className={'btn-light'}
                                            onClick={this.onAcceptSelectionClick}>{this.props.acceptSelectionLabel || 'Accept selection'}</button>
                                    <button className={'btn-dark'}
                                            onClick={this.onAcceptAllClick}>{this.props.acceptAllLabel || 'Accept all'}</button>
                                </>
                            )}

                            {!this.isSettingsOpen() && (
                                <>
                                    <button className={'btn-light'}
                                            onClick={this.onDeclineClick}>{this.props.declineLabel || 'Decline'}</button>
                                    <button className={'btn-dark'}
                                            onClick={this.onAcceptAllClick}>{this.props.acceptLabel || 'Accept'}</button>
                                </>
                            )}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
