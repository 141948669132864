import React from "react";
import Headline from "../../components/headline";
import * as styles from "./component.module.less";
import SectionContainer from "../../components/section-container";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const ContactPersonBlock = (props) => {
  const {
    headline,
    semanticOfHeadline,
    contactPerson,
    buttonLabel,
    mediaAlignRight,
    background
  } = props.data;

  const {
    name,
    role,
    phone,
    email,
    image
  } = contactPerson

  return (
    <SectionContainer
      className={styles.contactPersonContainer}
      sectionClass={styles.contactPersonSectionContainer}
      background={background}
      mediaAlignRight={mediaAlignRight}
      noContainer
    >
        <figure className={styles.contactPersonImage}>
            {image && (
                <GatsbyImage image={getImage(image.gatsbyImageData)} alt={image.alt ?? ''}/>
            )}
        </figure>

        <div className={styles.contactPersonContentWrapper}>
          <Headline
            className={styles.contactInfoHeadline} 
            semanticOfHeadline={semanticOfHeadline}
          >
            {headline}
          </Headline>

          <div className={styles.contactPersonInfo}>
            <p className={styles.name}>{name}</p>
            <p>{role}</p>
          </div>

          <div className={styles.contactInfoContainer}>
            <p className={styles.contactInfoPhone}>
              Telefon <br/>
              <a href={`tel:${phone}`}>{phone}</a>
            </p>

            <p className={styles.contactInfoEmail}>
              E-Mail <br/>
              <a href={`mailto:${email}`}>{email}</a>
            </p>

            <div className={styles.contactInfoButtonContainer}>
              <Link
                className='btn-dark'
                to={props.locale ? `/${props.locale}/${props.config.pageContact.slug}` : `/${props.config.pageContact.slug}`}
              >
                {buttonLabel ?? props.config.pageContact.name}
              </Link>
            </div>
          </div>
        </div>    
    </SectionContainer>
  );
};

export default ContactPersonBlock;
