import React from "react";
import * as styles from "./component.module.less";
import Headline from "../../components/headline";
import SectionContainer from "../../components/section-container";
import { GatsbyImage } from "gatsby-plugin-image";

const TrustLogo = ({
  background,
  tagline,
  semanticOfHeadline,
  headline,
  logos,
}) => {
  return (
    <SectionContainer className={styles.container} background={background}>
      <header>
        <span className={styles.tagline}>{tagline}</span>
        <Headline semanticOfHeadline={semanticOfHeadline}>{headline}</Headline>
      </header>
      <div className={styles.logos}>
        {logos.map((logo, i) => {
          return (
            <GatsbyImage
              image={logo.gatsbyImageData}
              alt={logo.alt}
              key={i}
              className={styles.image}
            />
          );
        })}
      </div>
    </SectionContainer>
  );
};

export default TrustLogo;
