import React from "react";
import * as styles from "./component.module.less";
import TeaserElement from "./2.04-teaser-element";
import Headline from "../../components/headline";
import ContactTeaserElement from "./2.04.1-service-contact-teaser-element";
import IconTeaserElement from "./2.04.2-icon-teaser-element";
import SectionContainer from "../../components/section-container";
import MediaTeaserElement from "./2.04.3-media-teaser-element";

const Teaser = (props) => {
  const {
    headline,
    semanticOfHeadline,
    teaser,
    semanticOfTeaserHeadline,
    background,
    // specific for 2.04
    showImage,
    showText,
    // specific for 2.04.1
    media,
    serviceHeadline,
    contactInfo,
    link,
    // specific for 2.04.3
    mediaTeaser
  } = props.data;

  const serviceTeaserData = {
    headline: serviceHeadline,
    semanticOfHeadline: semanticOfTeaserHeadline,
    media,
    contactInfo,
    link
  }

  return(
    <SectionContainer background={background ?? 'hell'} className={styles.teaserContainer} sectionClass={styles.teaserContentSection} noPadding={props.type === 'DatoCmsContentMediaTeaser'}>
      {headline &&
        <Headline className={styles.teaserContainerHeadline} semanticOfHeadline={semanticOfHeadline}>{headline}</Headline>
      }

      { renderContent() }
    </SectionContainer>
  )

  function renderContent(){
    switch (props.type) {
      // 2.04.1 Service-Contact-Teaser
      case "DatoCmsServiceContactTeaser":
      return (
        <div className={styles.teaser}>
          <ContactTeaserElement data={serviceTeaserData}/>

          {teaser.map((teaserElement, i) => (
            <TeaserElement 
              key={i}
              locale={props.locale}
              data={teaserElement}
              semanticOfHeadline={semanticOfTeaserHeadline}
            />
          ))}
        </div>
      );

      // 2.04.2 Content-Icon-Teaser
      case "DatoCmsContentIconTeaser":
        return(
          <div className={styles.iconTeaser}>
            {teaser.map(teaserElement => {
              return <IconTeaserElement 
                key={teaserElement.id}
                semanticOfHeadline={semanticOfTeaserHeadline}
                data={teaserElement}
                locale={props.locale}
              />
            })}
          </div>
        )

      // 2.04.3 Content-Media-Teaser
      case "DatoCmsContentMediaTeaser":
        return(
          <div className={styles.mediaTeaserContainer}>
            {mediaTeaser.map(mediaTeaserElement => {
              return <MediaTeaserElement 
                key={mediaTeaserElement.id}
                data={mediaTeaserElement}
              />
            })}
          </div>
        )

      // 2.04 Content-Teaser
      default:
        return (
          <div className={styles.teaser}>
            {teaser.map((teaserElement, i) => (
              <TeaserElement 
                key={i}
                locale={props.locale}
                data={teaserElement}
                semanticOfHeadline={semanticOfTeaserHeadline}
                showImage={showImage}
                showText={showText}
                strech={teaser.length < 3}
              />
            ))}
          </div>
        );
    }
  }

};

export default Teaser;
