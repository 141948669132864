import React from "react";
import { Field } from "formik";

export default function DateInput(props) {
	const { className, name, placeholder } = props;

	return (
		<Field
			className={className}
			name={name}
			type="date"
			placeholder={placeholder}
		/>
	);
}
