import React from "react";
import * as styles from "./component.module.less";
import Headline from "../../components/headline";
import SectionContainer from "../../components/section-container";
import Question from "./question";

export default function Faq(props) {
    const {
        headline,
        semanticOfHeadline,
        faqGroup,
        background,
    } = props.data

    return (
        <SectionContainer className={styles.faqSectionContainer} background={background}>
            <Headline className={styles.faqSectionHeadline}
                      semanticOfHeadline={semanticOfHeadline}>{headline}</Headline>

            <div className={styles.faqContainer}>
                {faqGroup.map(faqElemet => (
                    <div key={faqElemet.id} className={styles.faqGroup}>
                        <Headline
                            className={styles.faqGroupHeadline}
                            semanticOfHeadline={'H4'}
                        >
                            {faqElemet.faqHeadline}
                        </Headline>

                        <div className={styles.faqQuestionsContainer}>
                            {faqElemet.faq.map(question => (
                                <Question key={question.id} data={question}/>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </SectionContainer>
    );
};
