import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useRef, useState } from 'react'
import Popup from 'reactjs-popup';
import createHtmlClass from '../../../lib/helper/createHtmlClass';
import * as styles from "./component.module.less"

export default function MediaTeaserElement(props) {
    const {
        title,
        previewImage,
        video
    } = props.data;

    const [ showPlayButton, setShowPlayButton ] = useState(true)

    const refPopUp = useRef(null);
    const refVideoPlay = useRef()

    function playVideo() {
        setShowPlayButton(false);
        refVideoPlay.current?.play()
    }

    function closeVideo(closeModalMethod) {
        closeModalMethod()
        setShowPlayButton(true)
    }

    const popupButton = () => (
        <button className={styles.mediaTeaserElement} onClick={() => refPopUp.current.togglePopUp()}>
            <span className={styles.mediaTeaserTitle}>{title}</span>
            <div className={styles.mediaTeaserPlayButtonContainer}>
                <div
                    className={createHtmlClass([styles.mediaTeaserPlayButton,styles.mediaPlayButton, 'btn-dark'])}
                >
                </div>
            </div>
            <GatsbyImage
                className={styles.mediaTeaserThumbnail}
                image={getImage(previewImage.gatsbyImageData)}
                alt={previewImage.alt ?? ''} />
        </button>
    )

    return (
        <>
            {/* The styling of the popup overlay is in the global.less */}
            <Popup trigger={popupButton} modal>
                {close =>(
                    <div className={styles.mediaTeaserModal}>
                        <div className={styles.closeButtonContainer}>
                            <button
                                className="icon-btn btn-close"
                                onClick={() => closeVideo(close)}
                            >
                                close modal
                            </button>
                        </div>
                        <div className={styles.mediaContainer}>
                            <video 
                                ref={refVideoPlay}
                                className={styles.mediaTeaserVideo} 
                                controls
                            >
                                <source  src={video?.video?.mp4Url} type={`video/mp4`} />
                                <track kind="captions" />
                            </video>
                            
                            {showPlayButton &&
                                <button onClick={playVideo} className={styles.mediaTeaserModalPlayButtonContainer}>
                                    <div
                                        className={createHtmlClass([styles.mediaTeaserModalPlayButton,styles.mediaPlayButton, 'btn-dark'])}
                                    >
                                    </div>
                                </button>
                            }
                        </div>
                    </div>
                )}
            </Popup>
        </>
    )
}
