import React, {useEffect, useState} from "react"
import {graphql, StaticQuery} from "gatsby";
import { useCookies } from 'react-cookie'
import { StringParam, useQueryParam } from "use-query-params";

import Enabled from './components/enabled'
import View from './components/view'

export default props => {
    const isBrowser = typeof window !== "undefined"

    // check if url param disables cookie consent
  
    const [ disabledParam ] = !isBrowser ? [null] : useQueryParam('cc', StringParam);
    const isDisabled = disabledParam == null ? false : disabledParam === '0';
    const locale = props.locale || 'de';

    const [cookies, setCookie, removeCookie] = useCookies([]);

    const saveState = (cookieName, consent, groups) => {
        // "consent" is always bool
        // "groups" may be null (indicating that every group uses the "consent" value)

        // remove the old cookie first
        removeCookie(cookieName);

        // expires after 1 year
        const expires = new Date();
        expires.setFullYear((new Date()).getFullYear() + 1);

        /* eslint-disable eqeqeq */
        setCookie(cookieName, {
            consent,
            groups,
        }, {
            expires,
            path: '/',
            secure: process.env.GATSBY_COOKIE_SECURE == true,
            domain: process.env.GATSBY_COOKIE_DOMAIN,
            sameSite: process.env.GATSBY_COOKIE_SECURE == true ? 'strict' : false,
        });
        /* eslint-enable eqeqeq */
  };

  return (
      <StaticQuery
          query={graphql`
              query {
                  allDatoCmsConfig {
                      nodes {
                          locale
                          cookieConsentGroups {
                              uid
                              name
                              description
                          }
                          cookieConsentFunctions {
                              groupUid
                              kind
                              params
                          }
                          pageImprint {
                              name
                              slug
                          }
                          cookieConsentVersion
                          cookieConsentHeadline
                          cookieConsentIntro
                          cookieConsentNegBtnLabel
                          cookieConsentPosBtnLabel
                          cookieConsentSettingsBtnLabel
                          cookieConsentPosallBtnLabel
                          cookieConsentSaveselBtnLabel
                      }
                  }
              }
          `}
          render={data => {
              const config = data.allDatoCmsConfig.nodes.find(n => n.locale === locale);

              // cookie groups and functions
              const groups = (config.cookieConsentGroups || []);

              // functions
              const functions = (config.cookieConsentFunctions || []);

              // the cookie name is versioned
              // if the version changes, an existing cookie is no longer recognized and the cookie consent is shown
              const cookieName = 'swoecc' + config.cookieConsentVersion;

              // set default values for the overall consent and the consent to each group
              const [loaded, setLoaded] = useState(false);
              const [consent, setConsent] = useState(null);
              const [groupsConsent, setGroupsConsent] = useState(null);

              // load
              useEffect(() => {
                if (cookies[cookieName]) {
                  setConsent(cookies[cookieName].consent);
                  setGroupsConsent(cookies[cookieName].groups);
                }

                setLoaded(true);
              }, [cookieName]);

              return (
                  <div id="cookie-consent-container">
                    {loaded &&
                        <>
                          <Enabled
                              groupUids={groups.map(g => g.uid)}
                              enabled={groupsConsent}
                              functions={functions}
                              default={consent}
                          />

                          <View
                              ref={props.visibilityRef}
                              locale={props.locale}
                              onChoose={(con, groupCon) => {
                                saveState(cookieName, con, groupCon);
                                setGroupsConsent(groupCon);
                                setConsent(con);
                              }}
                              visible={consent == null && !isDisabled}
                              headline={config.cookieConsentHeadline}
                              intro={config.cookieConsentIntro}
                              settingsLabel={config.cookieConsentSettingsBtnLabel}
                              acceptLabel={config.cookieConsentPosBtnLabel}
                              declineLabel={config.cookieConsentNegBtnLabel}
                              acceptAllLabel={config.cookieConsentPosallBtnLabel}
                              acceptSelectionLabel={config.cookieConsentSaveselBtnLabel}
                              imprintPage={config.pageImprint}
                              default={consent}
                              groupsDefault={groupsConsent}
                              groups={groups}
                          />
                        </>
                    }
                  </div>
              );
          }}
      />
  );
}
