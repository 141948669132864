import React from "react"
import * as styles from "./component.module.less"
import Headline from "../../components/headline"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const HeaderText = (props) => {
    const {
        headline,
        semanticOfHeadline,
        mediaBackground
    } = props.data

    return (
        <section className={styles.headerTextContainer}>
            <div className={styles.headerTextSection}>
                <Headline
                    className={styles.headerTextHeadline}
                    accentColor={'#F9CB99'}
                    semanticOfHeadline={semanticOfHeadline}
                >
                    {headline}
                </Headline>
            </div>
            <GatsbyImage
                className={styles.headerTextBackgroundImage}
                image={getImage(mediaBackground.gatsbyImageData)}
                alt={mediaBackground.alt ?? ''}
            />
        </section>
    )

}

export default HeaderText;